import { basePath } from "./constants";

async function post (url, data, params) {
  try {
    const response = await fetch( basePath + url + "?" + new URLSearchParams(params), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    });
    if (response.status === 504) {
      return Promise.reject("504");
    }
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return await response.json();
  }
  catch (error) {
    return Promise.reject(error);
  }
}

async function uploadFile (url, file, params) {
  try {
    const response = await fetch( basePath + url + "?" + new URLSearchParams(params), {
      method: "PUT",
      body: file
    });
    if (response.status === 504) {
      return Promise.reject("504");
    }
    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return await response.json();
  }
  catch (error) {
    return Promise.reject(error);
  }
}


// 
// Endpoints
// 

export async function authenticate (emsNum) {
  return await post('/landing/auth', {emsNum});
}

export async function uploadPdfFile (file) {
  return await uploadFile('/landing/excel', file);
}

