import React, { useMemo } from 'react';
import { Portal, Snackbar, Alert } from '@mui/material';
import PropTypes from 'prop-types';

function Feedback ({ snackbar, onClose }) {
  const msg = useMemo(() => snackbar?.[0]?.toString() || "", [snackbar]);
  const isSuccess = useMemo(() => snackbar?.[1] || false, [snackbar]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!msg}
        onClose={onClose}
        autoHideDuration={isSuccess ? 2000 : 6000}
        transitionDuration={{ in: 0, appear: 250, enter: 250, exit: 0 }}
      >
        <Alert
          onClose={onClose}
          severity={isSuccess ? "success" : "error"}
          elevation={6}
          variant="filled"
        >
          {msg}
        </Alert>
      </Snackbar>
    </Portal>
  );
}

Feedback.propTypes = {
  snackbar: PropTypes.array,
  onClose: PropTypes.func
};

Feedback.defaultProps = {
  snackbar: "",
  isSuccess: false
};

export default Feedback;
