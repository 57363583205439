export const set_cookies = (cookies) => {
  Object.entries(cookies).forEach(([key, val]) => {
    document.cookie = encodeURIComponent(key) + "=" + encodeURIComponent(val) + "; samesite=Lax";
  });
};

export const get_cookies = () => {
  return document.cookie
    .split("; ")
    .reduce((a, v) => {
      const parsed = v.split("=");
      return {...a, [decodeURIComponent(parsed[0])]: decodeURIComponent(parsed[1])};
    }, {});
};