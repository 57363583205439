import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography } from '@mui/material';
import logo from '../res/logo.png';

const TopBar = ({ title }) => {
  return (
    <AppBar
      color="inherit"
      position="fixed"
      style={{ background: '#bbb', flexDirection: "row", alignItems: "center"}}
    >
      <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} height={32} style={{ marginRight: 16 }} alt="KUKA" />
        <Typography variant="h4">
          {title || ''}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  title: PropTypes.string
};

export default TopBar;